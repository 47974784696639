function toggleAccordion(element) {
    const container = document.querySelector(element.getAttribute('data-target-id'));

    const content = container.querySelector('.magellan-accordion-content');

    const icon = container.querySelector('.magellan-accordion-icon');

    if (content) {
        content.classList.toggle('expanded');
    }

    if (icon) {
        icon.classList.toggle('rotate');
    }

}

Liferay.ThemeDisplay = Liferay.ThemeDisplay || {};
Liferay.ThemeDisplay.toggleAccordion = toggleAccordion;
